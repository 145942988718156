jQuery(document).ready(function($) {

    if($('.js-switchtext').length) {
		var switchText = $('.js-switchtext').data('text');
		var switchArrayCount = switchText.length;
		var count = 1;

		window.setInterval(function() {
			$('.js-switchtext').addClass('transition');
			setTimeout(function() {
				$('.js-switchtext').text(switchText[count]).removeClass('transition');
			}, 500);

	 		if(count >= switchArrayCount - 1) {
	 			count = 0;
	 		} else {
	 			count++;
	 		}
		  
		}, 5000);
    }
 
});