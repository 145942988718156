jQuery(document).ready(function($) {
    
	$('.header__search').hover(function(e) {
		$('.header__search').addClass('header__search--active');
		$('#header__nav').addClass('header__nav--inactive');
	}, function() {
		$('.header__search').removeClass('header__search--active');
		$('#header__nav').removeClass('header__nav--inactive');
	});
 
});
