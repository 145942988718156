jQuery(document).ready(function($) {
    
	function iconPosition() {
		var iconPosition = $('.page__icon').scrollTop();
			windowPosition = $(document).scrollTop();
	}

	iconPosition();

	$(window).scroll(function() {
		iconPosition();
	});

 
});
