jQuery(document).ready(function($) {

	function productsShow() {
		$('#products-wrapper').each(function() {
			$(this).show();
		});
	}

	function productsReset() {
		$('.product-list .product').show().removeClass('product--filterhidden');
	}

	function productsFilter() {
		var activeTerms = [];
			activeTax = $('.products-filter.active').data('tax');

		$('.products-filter.active .products-filter__term.active').each(function() {
			activeTerms.push($(this).data('term'));
		});

		$('.product-list .product').each(function() {
			$(this).show().removeClass('product--filterhidden');

			var active = true;
				$product = $(this);

			if(activeTerms.length) {
				$.each(activeTerms, function(key, value) {
					if(!$product.hasClass('product__' + activeTax + '--' + value)) {
						active = false;
					}
				});				
			}
			
			if(active === false) {
				$(this).hide().addClass('product--filterhidden');
			}
		});
	}
    
	$('.js-products-load').click(function() {
		productsShow();
	});

	$('.js-products-filter-load').click(function() {
		var tax = $(this).data('tax');
		productsShow();
		$('.products-filter.active').removeClass('active');
		$('#products-filter--' + tax).addClass('active');
		$('.js-products-filter.active').removeClass('active');
		productsFilter();
	});

	$('.js-products-filter').click(function() {
		productsShow();
		$(this).toggleClass('active');
		productsFilter();
	});

	$('.product__search input').keyup(function() {
		var term = $(this).val().toLowerCase();
		
		// Loop through each visible product and show ones with either same heading or ref
		$('.product-list .product').each(function() {
			if(!$(this).hasClass('product--filterhidden')) {
				var heading = $('.product__heading', $(this)).text().toLowerCase();
				var ref = $(this).data('ref').toLowerCase();

				if(heading.indexOf(term) !== -1) {
					$(this).show();
				} else if(ref.indexOf(term) !== -1 && ref != '') {
					$(this).show();
				} else {
					$(this).hide();
				}				
			}
		});
	});
});
